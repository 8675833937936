import { useSpring } from '@react-spring/three';
import React, { useEffect, useRef, useState } from 'react';
import { RT } from '../../../../../stores';
import { MaterialFromBSDFModel } from '../MaterialFromBSDFModel';

interface Props {
  index: number;
  sphere: RT.SceneObject;
}

export const Sphere: React.FC<Props> = ({ index, sphere }) => {
  const ref = useRef<any>();
  const [objectID, setObjectID] = useState('');
  const actions = RT.useStore((store: RT.Store) => store.actions);
  const objects = RT.useStore((store: RT.Store) => store.state.objects);
  const geometryRef = useRef<any>(null);
  const geometry = geometryRef.current;

  const registerObject = (): void => {
    if (ref.current) {
      actions.registerObject(ref.current.uuid, RT.ESceneObject.SPHERE, index);
      setObjectID(ref.current.uuid);
    }
  };

  const selectObject = (): void => {
    if (ref.current) {
      actions.selectObject(ref.current.uuid, RT.ESceneObject.SPHERE);
    }
  };

  useEffect(() => {
    registerObject();
  });

  return (
    <mesh ref={ref} castShadow position={sphere.position as [number, number, number]} onPointerDown={selectObject}>
      <sphereGeometry ref={geometryRef} attach="geometry" args={[1, 16, 16]} />
      <MaterialFromBSDFModel BSDFModel={sphere.BSDFModel} object={sphere} />
    </mesh>
  );
};
