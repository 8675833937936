import { FormControlLabel, makeStyles, Slider, Switch } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useState } from 'react';

import { ColorSelector } from '../../../../components/colorSelector/ColorSelector';
import { LabelWithValue } from '../../../../components/forms/LabelWithValue';
import { StyledDivider } from '../../../../components/layouts/StyledDivider';
import { RT } from '../../../../stores';

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    width: 100,
  },
  selected: {
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

export const AreaLightOptions: React.FC = () => {
  const actions = RT.useStore((store: RT.Store) => store.actions);
  const areaLight = RT.useStore((store: RT.Store) => store.state.lights.area);
  const [position, setPosition] = useState<string>('left');

  function valuetext(value: number): string {
    return `${value}`;
  }

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newValue: string): void => {
    setPosition(newValue);
    switch (newValue) {
      case 'left':
        actions.setLightPosition([0, 4.9, 5], RT.ESceneLight.AREA);
        break;
      case 'top':
        actions.setLightPosition([0, 4.9, 0], RT.ESceneLight.AREA);
        break;
      case 'right':
        actions.setLightPosition([0, 4.9, -5], RT.ESceneLight.AREA);
        break;

      default:
        break;
    }
  };

  const classes = useStyles();
  return (
    <div style={{ maxHeight: '250px', overflowY: 'scroll' }}>
      <ColorSelector
        caption="light color"
        activeColor={areaLight.color}
        onColorChange={(color): void => actions.setLightColor(color, RT.ESceneLight.AREA)}
      />

      <StyledDivider />

      <LabelWithValue label={'Position'} value={''} />
      <div style={{ marginBottom: '1em' }}>
        <ToggleButtonGroup exclusive size="small" onChange={handleToggle}>
          <ToggleButton
            selected={position === 'left'}
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton}
            value="left"
          >
            Front
          </ToggleButton>
          <ToggleButton
            selected={position === 'top'}
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton}
            value="top"
          >
            Mid
          </ToggleButton>
          <ToggleButton
            selected={position === 'right'}
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton}
            value="right"
          >
            Back
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <StyledDivider />
      <LabelWithValue label={'Intensity'} value={areaLight.intensity} />
      <Slider
        value={areaLight.intensity}
        getAriaValueText={valuetext}
        step={0.1}
        min={0}
        max={1}
        marks
        onChange={(_, v): void => actions.setLightIntensity(+v, RT.ESceneLight.AREA)}
      />

      <StyledDivider />
      <LabelWithValue label={'width'} value={areaLight.width} />
      <Slider
        value={areaLight.width}
        getAriaValueText={valuetext}
        step={1}
        min={1}
        max={5}
        marks
        onChange={(_, v): void => actions.setLightWidth(+v, RT.ESceneLight.AREA)}
      />

      <StyledDivider />
      <LabelWithValue label={'height'} value={areaLight.height} />
      <Slider
        value={areaLight.height}
        getAriaValueText={valuetext}
        step={1}
        min={1}
        max={5}
        marks
        onChange={(_, v): void => actions.setLightHeight(+v, RT.ESceneLight.AREA)}
      />
    </div>
  );
};
