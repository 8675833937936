/* eslint-disable @typescript-eslint/naming-convention */
import { FormControlLabel, TextField, Switch } from '@material-ui/core';
import React from 'react';

import { LabelWithValue } from '../../../../../components/forms/LabelWithValue';
import { StyledDivider } from '../../../../../components/layouts/StyledDivider';
import { RT } from '../../../../../stores';
import { DielectricParameter } from '../../../../../stores/raytracing/types';

interface Props {
  objectData: RT.SceneObject;
}

export const BSDFModel: React.FC<Props> = ({ objectData }) => {
  const actions = RT.useStore((store: RT.Store) => store.actions);

  const BSDFModel = objectData.BSDFModel;

  function valuetext(value: number): string {
    return `${value}`;
  }

  return <>{objectData.BSDFModel === RT.EBSDFModel.DIELECTRIC ? <></> : null}</>;
};
