import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useCallback } from 'react';

import { PBR } from '../../../stores';
import PanelSlider from '../../../components/forms/PanelSlider';
import { useEnv } from '../EnvContext';
import { useDropzone } from 'react-dropzone';

const ScenePicker: React.FC = () => {
  const scene1 = PBR.useStore((state: PBR.State) => state.scene);
  const actions = PBR.useStore((state: PBR.State) => state.actions);
  const { env, setEnv } = useEnv();

  const useStyles = makeStyles((theme) => ({
    group: {
      background: 'white',
    },
    toggleButton1: {
      width: 85,
    },
    toggleButton2: {
      width: 65,
    },
    selected: {
      '&$selected': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  }));

  const onDrop = useCallback((acceptedFiles: any[]) => {
    let rootFile: any;
    let rootPath: any;
    let fileURL = null;
    acceptedFiles.forEach((file: any) => {
      if (file.name.match(/\.(hdr|HDR)$/)) {
        rootFile = file;
        rootPath = file.path.replace(file.name, '');
        fileURL = typeof rootFile === 'string' ? rootFile : URL.createObjectURL(rootFile);
        setEnv(fileURL);
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const classes = useStyles();

  return (
    <>
      {!env ? (
        <ToggleButtonGroup className={classes.group}>
          <ToggleButton
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton1}
            onClick={(): void => actions.setBackground(1)}
            selected={scene1.background === 1}
            key={1}
            value={'toggle'}
          >
            <Typography variant="subtitle2">Studio</Typography>
          </ToggleButton>

          <ToggleButton
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton1}
            onClick={(): void => actions.setBackground(2)}
            selected={scene1.background === 2}
            key={2}
            value={'toggle'}
          >
            <Typography variant="subtitle2">Street</Typography>
          </ToggleButton>
          <ToggleButton
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton1}
            onClick={(): void => actions.setBackground(3)}
            selected={scene1.background === 3}
            key={3}
            value={'toggle'}
          >
            <Typography variant="subtitle2">Garden</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      ) : (
        <ToggleButtonGroup className={classes.group}>
          <ToggleButton
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton2}
            onClick={(): void => actions.setBackground(1)}
            selected={scene1.background === 1}
            key={1}
            value={'toggle'}
          >
            <Typography variant="subtitle2">Studio</Typography>
          </ToggleButton>

          <ToggleButton
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton2}
            onClick={(): void => actions.setBackground(2)}
            selected={scene1.background === 2}
            key={2}
            value={'toggle'}
          >
            <Typography variant="subtitle2">Street</Typography>
          </ToggleButton>
          <ToggleButton
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton2}
            onClick={(): void => actions.setBackground(3)}
            selected={scene1.background === 3}
            key={3}
            value={'toggle'}
          >
            <Typography variant="subtitle2">Garden</Typography>
          </ToggleButton>
          <ToggleButton
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton2}
            onClick={(): void => actions.setBackground(4)}
            selected={scene1.background === 4}
            key={4}
            value={'toggle'}
          >
            <Typography variant="subtitle2">File</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      <div
        {...getRootProps()}
        style={{ borderRadius: '0.5', background: '#333333', textAlign: 'center', height: '25px' }}
      >
        <input {...getInputProps()} />
        <p style={{ color: '#dddddd' }}>drop or select .hdr flie</p>
      </div>
      <PanelSlider
        label={'Environment Intensity'}
        value={scene1.backgroundIntensity}
        step={0.1}
        min={0}
        max={1}
        onChange={(_, v): void => actions.setBackgroundIntensity(+v)}
      />
    </>
  );
};

export default ScenePicker;
