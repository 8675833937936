import React, { useRef, useState } from 'react';
import * as THREE from 'three';

import { RT } from '../../../../../stores';

export const PointLight: React.FC = () => {
  const lightData = RT.useStore((store: RT.Store) => store.state.lights['point']);
  const ref = useRef<THREE.PointLight>(null!);

  return (
    <>
      <pointLight
        position={[0, 3.5, 0]}
        color={(lightData.color as unknown) as THREE.Color}
        intensity={lightData.intensity}
        ref={ref}
      />
    </>
  );
};
