import React from 'react';

interface SceneInfoProps {
  faceCount: number;
  sampleNum: number;
  renderTime: number;
}

export const SceneInfo: React.FC<SceneInfoProps> = ({ faceCount, sampleNum, renderTime }) => {
  return (
    <div style={{ position: 'absolute', bottom: 10, left: 10, color: 'white' }}>
      <div>Face Count: {faceCount}</div>
      <div>Sample Num: {sampleNum}</div>
      <div>Render Time last Sample: {renderTime.toFixed(2)} ms</div>
    </div>
  );
};
