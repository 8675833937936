import {
  ESceneLight,
  ESceneObject,
  EBSDFModel,
  SceneObject,
  BSDFParameters,
  DielectricParameter,
  DiffuseParameter,
  State,
  EIntegrator,
} from './types';

export const lights = {
  activeLightSettings: '',
  area: {
    isActive: true,
    showHelper: false,
    name: 'Arealight',
    type: ESceneLight.AREA,
    position: [0, 4.9, 0],
    intensity: 0.6,
    color: '#FFFFFF',
    width: 2,
    height: 2,
  },
  point: {
    isActive: false,
    showHelper: false,
    name: 'Pointlight',
    type: ESceneLight.POINT,
    position: [0, 6, 0],
    intensity: 0.6,
    color: '#FFFFFF',
  },
};

/* eslint-disable @typescript-eslint/naming-convention */

export const initialBSDFParameters: BSDFParameters = {};

export const initialDiffuseParameters: DiffuseParameter = {};

export const initialDielectricParameters: DielectricParameter = {
  m_IOR: 1.5, // default values for m_intIOR and m_extIOR
};

export const spheres: SceneObject[] = [
  {
    type: ESceneObject.SPHERE,
    id: '',
    selected: false,
    visible: true,
    position: [3, -3, 0],
    color: '#FFFFFF',
    BSDFModel: EBSDFModel.DIFFUSE,
    BSDFParameters: {
      diffuse: initialDiffuseParameters,
      dielectric: initialDielectricParameters,
      mirror: initialBSDFParameters,
    },
  },
  {
    type: ESceneObject.SPHERE,
    id: '',
    selected: false,
    visible: true,
    position: [0, -3, 0],
    color: '#FFFFFF',
    BSDFModel: EBSDFModel.DIFFUSE,
    BSDFParameters: {
      diffuse: initialDiffuseParameters,
      dielectric: initialDielectricParameters,
      mirror: initialBSDFParameters,
    },
  },
  {
    type: ESceneObject.SPHERE,
    id: '',
    selected: false,
    visible: true,
    position: [-3, -3, 0],
    color: '#FFFFFF',
    BSDFModel: EBSDFModel.DIFFUSE,
    BSDFParameters: {
      diffuse: initialDiffuseParameters,
      dielectric: initialDielectricParameters,
      mirror: initialBSDFParameters,
    },
  },
];

export const tori: SceneObject[] = [
  {
    type: ESceneObject.TORUS,
    id: '',
    selected: false,
    visible: true,
    position: [3, -3, 3],
    color: '#FFFFFF',
    BSDFModel: EBSDFModel.DIFFUSE,
    BSDFParameters: {
      diffuse: initialDiffuseParameters,
      dielectric: initialDielectricParameters,
      mirror: initialBSDFParameters,
    },
  },
  {
    type: ESceneObject.TORUS,
    id: '',
    selected: false,
    visible: true,
    position: [0, -3, 3],
    color: '#FFFFFF',
    BSDFModel: EBSDFModel.DIFFUSE,
    BSDFParameters: {
      diffuse: initialDiffuseParameters,
      dielectric: initialDielectricParameters,
      mirror: initialBSDFParameters,
    },
  },
  {
    type: ESceneObject.TORUS,
    id: '',
    selected: false,
    visible: true,
    position: [-3, -3, 3],
    color: '#FFFFFF',
    BSDFModel: EBSDFModel.DIFFUSE,
    BSDFParameters: {
      diffuse: initialDiffuseParameters,
      dielectric: initialDielectricParameters,
      mirror: initialBSDFParameters,
    },
  },
];

export const boxes: SceneObject[] = [
  {
    type: ESceneObject.BOX,
    id: '',
    selected: false,
    visible: true,
    position: [3, -3, -3],
    color: '#FFFFFF',
    BSDFModel: EBSDFModel.DIFFUSE,
    BSDFParameters: {
      diffuse: initialDiffuseParameters,
      dielectric: initialDielectricParameters,
      mirror: initialBSDFParameters,
    },
  },
  {
    type: ESceneObject.BOX,
    id: '',
    selected: false,
    visible: true,
    position: [0, -3, -3],
    color: '#FFFFFF',
    BSDFModel: EBSDFModel.DIFFUSE,
    BSDFParameters: {
      diffuse: initialDiffuseParameters,
      dielectric: initialDielectricParameters,
      mirror: initialBSDFParameters,
    },
  },
  {
    type: ESceneObject.BOX,
    id: '',
    selected: false,
    visible: true,
    position: [-3, -3, -3],
    color: '#FFFFFF',
    BSDFModel: EBSDFModel.DIFFUSE,
    BSDFParameters: {
      diffuse: initialDiffuseParameters,
      dielectric: initialDielectricParameters,
      mirror: initialBSDFParameters,
    },
  },
];

export const initialState: State = {
  globals: {
    BSDFModel: 'diffuse',
  },
  scene: {
    integrator: EIntegrator.WHITTED,
    bounce: 3,
    backgroundColor: '#333333',
    showPlane: false,
    showCornellBox: true,
  },
  objects: {
    selectedObject: {
      id: '',
      type: ESceneObject.SPHERE,
    },
    groups: {
      torus: true,
      sphere: true,
      box: true,
    },
    sphere: [...spheres],
    box: [...boxes],
    torus: [...tori],
  },
  lights,
};
