import { Vector3 } from 'three';

import { Box, Cerberus, ESceneObject, Scene, Sphere, Torus } from '../types';

export const initialScene: Scene = {
  activeObject: ESceneObject.Sphere,
  hasAxisHelper: false,
  modelColor: '#6fa8dc',
  wireframeColor: 'white',
  wireframeLineWidth: 2,
  normalsLength: 0.3,
  faceNormalsColor: 0xff0000,
  vertexNormalsColor: 0x12ec5b,
  backgroundColor: '#333333',
  moveLight: false,
  background: 1,
  backgroundIntensity: 0.7,
};

export const initialBox: Box = {
  name: 'box',
  savedCam: {
    position: new Vector3(5, 5, 5),
    rotation: new Vector3(0, 0, 0),
  },
  scale: [1, 1, 1],
  color: 'gray',
  width: 2,
  maxWidth: 4,
  minWidth: 1,
  height: 2,
  maxHeight: 4,
  minHeight: 1,
  depth: 2,
  maxDepth: 4,
  minDepth: 1,
  widthSegments: 8,
  maxWidthSegments: 8,
  minWidthSegments: 1,
  heightSegments: 8,
  maxHeightSegments: 8,
  minHeightSegments: 1,
  depthSegments: 8,
  maxDepthSegments: 8,
  minDepthSegments: 1,
  isVisible: true,
  isFlat: false,
  isWireframe: false,
  isFaceNormals: false,
  isVertexNormals: false,
  metalness: 0.2,
  roughness: 0.8,
  transparency: 0,
  refractionRatio: 0,
};

export const initialSphere: Sphere = {
  name: 'sphere',
  savedCam: {
    position: new Vector3(5, 5, 5),
    rotation: new Vector3(0, 0, 0),
  },
  scale: [1, 1, 1],
  color: 'red',
  radius: 1.5,
  maxRadius: 2,
  minRadius: 1,
  widthSegments: 1024,
  maxWidthSegments: 64,
  minWidthSegments: 3,
  heightSegments: 1024,
  maxHeightSegments: 64,
  minHeightSegments: 3,
  isVisible: true,
  isFlat: false,
  isWireframe: false,
  isFaceNormals: false,
  isVertexNormals: false,
  // PBR
  metalness: 0.95,
  roughness: 0.05,
  transparency: 0,
  refractionRatio: 1,
  havemap: false,
  stainless: true,
  envMapIntensity: 1,
};

export const initialTorus: Torus = {
  name: 'torus',
  savedCam: {
    position: new Vector3(5, 5, 5),
    rotation: new Vector3(0, 0, 0),
  },
  position: [0, 0, 0],
  scale: [1, 1, 1],
  color: 'gray',

  radius: 2,
  maxRadius: 3,
  minRadius: 1,

  tubeWidth: 0.6,
  maxTubeWidth: 1,
  minTubeWidth: 0.1,

  radialSegments: 1024,
  maxRadialSegments: 40,
  minRadialSegments: 2,

  tubularSegments: 1024,
  maxTubularSegments: 200,
  minTubularSegments: 3,

  isVisible: true,
  isFlat: false,
  isWireframe: false,
  isFaceNormals: false,
  isVertexNormals: false,

  metalness: 0,
  roughness: 0.1,
  opacity: 1,
  refractionRatio: 0,
  reflectivity: 1,
  isTransparent: true,
  transmission: 1,
};

export const initialCerberus: Cerberus = {
  name: 'Cerberus',
  savedCam: {
    position: new Vector3(5, 5, 5),
    rotation: new Vector3(0, 0, 0),
  },
  scale: [1, 1, 1],
  color: 'gray',
  width: 6,
  maxWidth: 8,
  minWidth: 1,
  height: 6,
  maxHeight: 8,
  minHeight: 1,
  widthSegments: 8,
  maxWidthSegments: 8,
  minWidthSegments: 1,
  heightSegments: 8,
  maxHeightSegments: 8,
  minHeightSegments: 1,
  isVisible: true,
  metalness: 0.9,
  roughness: 0.9,
  transparency: 0,
  refractionRatio: 0,
  normalMap: true,
  roughnessMap: true,
  metalnessMap: true,
  colorMap: true,
  aoMap: true,
};
