import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { FullScreenQuad } from 'three-stdlib';
import { useFrame, useThree } from '@react-three/fiber';

import { RT } from '../../../stores';

import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { INTEGRATOR_whitted } from './integrators/whitted';

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

interface FSQuadProps {
  onFaceCountChange: (count: number) => void;
  onSampleNumChange: (count: number) => void;
  onRenderTimeChange: (time: number) => void;
}

export const FSQuad: React.FC<FSQuadProps> = ({ onFaceCountChange, onSampleNumChange, onRenderTimeChange }) => {
  const backgroundColorStr = RT.useStore((store) => store.state.scene.backgroundColor);
  const sceneState = RT.useStore((store) => store.state.scene);
  const objectState = RT.useStore((store) => store.state.objects);
  const lightState = RT.useStore((store) => store.state.lights);
  const { size, scene, camera, gl } = useThree();
  const [dataTexture, setDataTexture] = useState<THREE.DataTexture | undefined>(undefined);
  const updateIteratorRef = useRef<Iterator<void, void, unknown>>();
  const oldMatrixWorldRef = useRef<THREE.Matrix4>(new THREE.Matrix4());
  let worldMatrixChanged = false;

  let sample = 0;

  useEffect(() => {
    if (dataTexture) {
      // @ts-ignore
      // console.log('dispose', dataTexture, fsQuadRef.current?.material.map);
      dataTexture.dispose();
    }
    const newDataTexture = new THREE.DataTexture(
      new Float32Array(size.width * size.height * 4),
      size.width,
      size.height,
      THREE.RGBAFormat,
      THREE.FloatType,
    );
    setDataTexture(newDataTexture);
    // resetImage();
    // console.log('image reset');
  }, [size]);

  useEffect(() => {
    let count = 0;
    let allobjects = scene.children;
    allobjects.forEach((child) => {
      if (child instanceof THREE.Mesh) {
        count += child.geometry.getIndex().count / 3;
      }
    });
    onFaceCountChange(count);

    resetImage();
  }, [sceneState, objectState.groups, objectState.sphere, objectState.torus, objectState.box, lightState]);

  // const resizeDataTexture = () => {
  //     const newDataTexture = new THREE.DataTexture(
  //       new Float32Array(size.width * size.height * 4),
  //       size.width,
  //       size.height,
  //       THREE.RGBAFormat,
  //       THREE.FloatType,
  //     );
  //     setDataTexture(newDataTexture);
  //     resetImage();
  // };

  function resetImage() {
    if (dataTexture) {
      dataTexture.image.data.fill(0);
      dataTexture.needsUpdate = true;
      updateIteratorRef.current = updateDataTexture();
    }
  }

  function* updateDataTexture() {
    const { width, height } = size;

    const allobjects = scene.children.filter((object) => object instanceof THREE.Mesh || object instanceof THREE.Light);

    allobjects.forEach((child) => {
      if (child instanceof THREE.Mesh) {
        child.geometry.computeBoundsTree();
      }
    });

    const emitters: THREE.Light[] = [];
    const meshes: THREE.Object3D<THREE.Event>[] = [];
    allobjects.forEach((eachobject) => {
      if (
        eachobject instanceof THREE.Light &&
        !(eachobject instanceof THREE.AmbientLight || eachobject instanceof THREE.DirectionalLight)
      ) {
        emitters.push(eachobject);
      } else {
        meshes.push(eachobject);
      }
    });

    if (dataTexture) {
      const { data } = dataTexture.image;

      let radianceColor = new THREE.Color();

      const raycaster = new THREE.Raycaster();
      raycaster.firstHitOnly = true;
      const seedRay = new THREE.Ray();
      const startPoint = new THREE.Vector2();
      const viewDir = new THREE.Vector3();

      let lastStartTime = performance.now();
      let lastSampleTime = performance.now();
      sample = 0;
      while (sample < 30) {
        let randomOffsetX = Math.random();
        let randomOffsetY = Math.random();
        for (let y = height - 1; y >= 0; y--) {
          const tempRowData = data.slice(y * width * 4, (y + 1) * width * 4);

          for (let x = 0; x < width; x++) {
            const index = (y * width + x) * 4;
            data[index + 0] = 1.0;
            data[index + 1] = 0.0;
            data[index + 2] = 0.0;
            data[index + 3] = 1.0;
          }

          for (let x = 0; x < width; x++) {
            const index = (y * width + x) * 4;
            const r = tempRowData[4 * x + 0];
            const g = tempRowData[4 * x + 1];
            const b = tempRowData[4 * x + 2];

            startPoint.set((randomOffsetX + x) / (width - 1), (randomOffsetY + y) / (height - 1));
            const screenPoint = new THREE.Vector2(startPoint.x * 2 - 1, startPoint.y * 2 - 1);
            raycaster.setFromCamera(screenPoint, camera);
            viewDir.set(0, 0, -1).transformDirection(camera.matrixWorld);

            seedRay.direction.copy(raycaster.ray.direction.normalize());
            seedRay.origin
              .copy(raycaster.ray.origin)
              .addScaledVector(raycaster.ray.direction, camera.near / raycaster.ray.direction.dot(viewDir));
            radianceColor = INTEGRATOR_whitted(
              seedRay,
              allobjects,
              sceneState,
              emitters,
              meshes,
              lightState.area.isActive,
            );

            const delta = performance.now() - lastStartTime;
            if (delta > 16) {
              yield;
              lastStartTime = performance.now();
            }

            data[index + 0] = r + (radianceColor.r - r) / (sample + 1);
            data[index + 1] = g + (radianceColor.g - g) / (sample + 1);
            data[index + 2] = b + (radianceColor.b - b) / (sample + 1);
            data[index + 3] = 1.0;
          }
        }
        sample++;

        const oneSampleTime = performance.now() - lastSampleTime;
        onSampleNumChange(sample);
        onRenderTimeChange(oneSampleTime);
        lastSampleTime = performance.now();
      }
      dataTexture.needsUpdate = true;
    }
  }

  const fsQuadRef = useRef<FullScreenQuad>();

  useEffect(() => {
    // console.log('datatexture update');
    if (!fsQuadRef.current && dataTexture) {
      const material = new THREE.MeshBasicMaterial({ map: dataTexture, blending: THREE.CustomBlending });
      const fsQuad = new FullScreenQuad(material);
      fsQuadRef.current = fsQuad;
      // console.log('update fsquad');
    } else if (dataTexture) {
      // console.log('update datatexture');
      // @ts-ignore
      fsQuadRef.current.dispose();
      const material = new THREE.MeshBasicMaterial({ map: dataTexture, blending: THREE.CustomBlending });
      const fsQuad = new FullScreenQuad(material);
      fsQuadRef.current = fsQuad;
      resetImage();
    }
  }, [dataTexture]);

  useFrame(() => {
    gl.render(scene, camera);

    if (fsQuadRef.current) {
      gl.autoClear = false;
      fsQuadRef.current.render(gl);
      gl.autoClear = true;

      // if((oldSizeHeight.current !== size.height) || (oldSizeWidth.current !== size.width)){
      //   resizeDataTexture();
      //   oldSizeHeight.current = size.height;
      //   oldSizeWidth.current = size.width;
      // }

      if (!oldMatrixWorldRef.current.equals(camera.matrixWorld)) {
        resetImage();
        onSampleNumChange(0);
        onRenderTimeChange(0);
        oldMatrixWorldRef.current.copy(camera.matrixWorld);
      }

      if (updateIteratorRef.current) {
        let result = updateIteratorRef.current.next();
      }

      if (dataTexture) {
        dataTexture.needsUpdate = true;
      }
    }
  }, 1);

  return <>{}</>;
};
