import { Box, Button, makeStyles, Slider, Tooltip, Typography } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { ReactElement } from 'react';

import { ColorSelector } from '../../../../components/colorSelector/ColorSelector';
import { LabelWithValue } from '../../../../components/forms/LabelWithValue';
import { StyledDivider } from '../../../../components/layouts/StyledDivider';
import { RT } from '../../../../stores';
import { PlaneVisibilityToggle } from '../PlaneVisibilityToggle/PlaneVisibilityToggle';

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    width: 145,
  },
  selected: {
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

export function GlobalOptions(): ReactElement {
  const backgroundColor = RT.useStore((store: RT.Store) => store.state.scene.backgroundColor);
  const globals = RT.useStore((store: RT.Store) => store.state.globals);
  const scenes = RT.useStore((store: RT.Store) => store.state.scene);
  const settingName = RT.useStore((store: RT.Store) => store.default.name);
  const showCornellBox = RT.useStore((store: RT.Store) => store.state.scene.showCornellBox);

  const setBackgroundColor = RT.useStore((store: RT.Store) => store.actions.setBackgroundColor);
  const resetState = RT.useStore((store: RT.Store) => store.actions.resetState);
  const resetLights = RT.useStore((store: RT.Store) => store.actions.resetLights);
  const resetObjects = RT.useStore((store: RT.Store) => store.actions.resetObjects);
  const setPathParameters = RT.useStore((store: RT.Store) => store.actions.setPathParameters);
  const setBSDFModel = RT.useStore((store: RT.Store) => store.actions.setGlobalBSDFModel);

  function valuetext(value: number): string {
    return `${value}`;
  }

  const handleToggle = (event: React.MouseEvent<HTMLElement>, newValue: RT.EBSDFModel | string): void => {
    setBSDFModel(newValue as RT.EBSDFModel);
  };

  const classes = useStyles();

  return (
    <div>
      <Box textAlign="center">
        <Typography variant="caption" color="textSecondary">
          current base setting: <span style={{ fontWeight: 'bold' }}>{settingName}</span>
        </Typography>
      </Box>
      <ColorSelector
        caption="background color"
        activeColor={backgroundColor}
        onColorChange={(color): void => setBackgroundColor(color)}
        bgColor={true}
      />
      <StyledDivider />

      <>
        <StyledDivider />
        <LabelWithValue label={'Recursion Depth'} value={scenes.bounce} />
        <Slider
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={1}
          min={1}
          max={10}
          marks
          value={scenes.bounce}
          onChange={(_, v): void => {
            setPathParameters(+v);
          }}
        />
      </>

      {!showCornellBox && (
        <div style={{ marginBottom: '1em' }}>
          <ToggleButtonGroup exclusive size="small" onChange={handleToggle}>
            <ToggleButton
              selected={globals.BSDFModel === RT.EBSDFModel.DIFFUSE}
              classes={{
                selected: classes.selected,
              }}
              className={classes.toggleButton}
              value={RT.EBSDFModel.DIFFUSE}
            >
              DIFFUSE
            </ToggleButton>
            <ToggleButton
              selected={globals.BSDFModel === RT.EBSDFModel.MIRROR}
              classes={{
                selected: classes.selected,
              }}
              className={classes.toggleButton}
              value={RT.EBSDFModel.MIRROR}
            >
              MIRROR
            </ToggleButton>
            {/* <ToggleButton
            selected={globals.BSDFModel === RT.EBSDFModel.DIELECTRIC}
            classes={{
              selected: classes.selected,
            }}
            className={classes.toggleButton}
            value={RT.EBSDFModel.DIELECTRIC}
          >
            GLASS
          </ToggleButton> */}
          </ToggleButtonGroup>
        </div>
      )}

      <StyledDivider />
      <div style={{ marginBottom: '1em' }}>
        <Typography gutterBottom>Resets</Typography>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Tooltip title="reset all lights to the default">
            <Button startIcon={<RotateLeftIcon />} size="small" onClick={(): void => resetLights()} variant="outlined">
              lights
            </Button>
          </Tooltip>
          <Tooltip title="reset all object to the default">
            <Button
              startIcon={<RotateLeftIcon />}
              size="small"
              onClick={(): void => {
                resetObjects();
              }}
              variant="outlined"
            >
              objects
            </Button>
          </Tooltip>
          <Tooltip title="reset everything to the default">
            <Button
              startIcon={<RotateLeftIcon />}
              size="small"
              onClick={(): void => {
                resetState();
              }}
              variant="outlined"
            >
              all
            </Button>
          </Tooltip>
        </div>
      </div>
      <StyledDivider />
      <div>
        <PlaneVisibilityToggle />
      </div>
    </div>
  );
}
