import React from 'react';

import { RT } from '../../../../stores';
import { PointLight } from './PointLight';
import { AreaLight } from './AreaLight';

export const Lights: React.FC = () => {
  const lights = RT.useStore((store: RT.Store) => store.state.lights);

  return (
    <>
      {lights['point'].isActive ? <PointLight /> : null}
      {lights['area'].isActive ? <AreaLight /> : null}
    </>
  );
};
