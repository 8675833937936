import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

import { RT } from '../../../../../stores';
import { RectAreaLightHelper, RectAreaLightUniformsLib } from 'three-stdlib';
import { useThree } from '@react-three/fiber';

export const AreaLight: React.FC = () => {
  const lightData = RT.useStore((store: RT.Store) => store.state.lights['area']);
  const ref = useRef<THREE.RectAreaLight>(null!);
  const { scene } = useThree();
  const light = ref.current;

  useEffect(() => {
    if (light) {
      RectAreaLightUniformsLib.init();
      const lightHelper = new RectAreaLightHelper(light);
      scene.add(lightHelper);

      return () => {
        scene.remove(lightHelper);
        lightHelper.dispose();
      };
    }
  }, [scene, light]);

  return (
    <>
      <rectAreaLight
        intensity={lightData.intensity}
        visible={true}
        height={lightData.height}
        width={lightData.width}
        position={lightData.position as [number, number, number]}
        rotation={[-Math.PI / 2, 0, 0]}
        ref={ref}
        color={(lightData.color as unknown) as THREE.Color}
      />
    </>
  );
};
