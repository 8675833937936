import produce, { Draft } from 'immer';

import { initialState } from '../initialState';
import { State } from '../types';

export interface Config {
  name: string;
  config: State;
}

export const CNB: Config = {
  name: 'Cornell Box',
  config: { ...initialState },
};

export const Plane: Config = {
  name: 'Objects',
  config: produce(initialState, (draft: Draft<State>) => {
    draft.scene.showPlane = true;
    draft.scene.showCornellBox = false;
  }),
};

export const premades = [CNB, Plane];
