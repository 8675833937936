import React from 'react';
import { a } from '@react-spring/three';
import { MaterialFromBSDFModel } from '../MaterialFromBSDFModel';
import { RT } from '../../../../../stores';

export const CornellBox: React.FC = () => {
  const plane = <boxGeometry attach="geometry" args={[10, 10, 0.05, 1, 1, 1]} />;

  return (
    <>
      <mesh position={[0, 0, -5]} name={'back'}>
        <meshPhongMaterial attach="material" color={'#aabbcc'} />
        {plane}
      </mesh>

      <mesh position={[0, 5, 0]} name={'up'} rotation={[Math.PI / 2, 0, 0]}>
        <meshPhongMaterial attach="material" color={'#bbeedd'} />
        {plane}
      </mesh>
      <mesh position={[0, -5, 0]} name={'bottom'} rotation={[Math.PI / 2, 0, 0]}>
        <meshPhongMaterial attach="material" color={'#a2a2a2'} />
        {plane}
      </mesh>

      <mesh position={[5, 0, 0]} name={'right'} rotation={[0, Math.PI / 2, 0]}>
        <meshPhongMaterial attach="material" color={'#edb0ab'} />
        {plane}
      </mesh>
      <mesh position={[-5, 0, 0]} name={'left'} rotation={[0, Math.PI / 2, 0]}>
        <meshPhongMaterial attach="material" color={'#a39ede'} />
        {plane}
      </mesh>
      <mesh position={[2.5, -3.5, -1]}>
        <meshPhysicalMaterial ior={1.5} transmission={1.0} opacity={1.0} transparent={true} />
        <sphereGeometry attach="geometry" args={[1.5, 16, 16]} />
      </mesh>

      <mesh position={[-2, -3.5, -1]}>
        <meshStandardMaterial roughness={0.0} metalness={1.0} />
        <sphereGeometry attach="geometry" args={[1.5, 16, 16]} />
      </mesh>
    </>
  );
};
