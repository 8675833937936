import React from 'react';
import * as THREE from 'three';

import { sample_emitter } from '../Lights/getemitter';

const epsilon = 0.000001;

export const INTEGRATOR_whitted = (
  seedray: THREE.Ray,
  objects: THREE.Object3D<THREE.Event>[],
  sceneState:
    | {
        integrator: any;
        bounce?: number | undefined;
        backgroundColor?: string | undefined;
        showPlane?: boolean | undefined;
        showCornellBox?: boolean | undefined;
      }
    | undefined,
  emitters: any[],
  meshes: THREE.Object3D<THREE.Event>[],
  areaLightState: boolean,
): THREE.Color => {
  let raycaster = new THREE.Raycaster();
  let hit = null;
  let current_bounce = 0;
  const backgroundColorStr = sceneState?.backgroundColor;
  const backgroundColor = new THREE.Color(backgroundColorStr);
  const bounce = sceneState?.bounce;
  const lightMesh = areaLightState ? meshes[0] : null;

  function trace(currentRay: THREE.Ray) {
    raycaster.ray.copy(currentRay);
    raycaster.near = epsilon;
    hit = raycaster.intersectObjects(objects, true)[0];
    current_bounce = current_bounce + 1;
    if (bounce && current_bounce > bounce) return new THREE.Color(0, 0, 0);
    if (hit) return shade(hit);
    else return backgroundColor;
  }

  function getRandomReflectionDirection(normal: THREE.Vector3) {
    let randomPoint = new THREE.Vector3(
      Math.random() * 2 - 1,
      Math.random() * 2 - 1,
      Math.random() * 2 - 1,
    ).normalize();

    if (randomPoint.dot(normal) < 0) {
      randomPoint.multiplyScalar(-1);
    }

    return randomPoint;
  }

  function shade(hit: THREE.Intersection<THREE.Object3D<THREE.Event>>) {
    let color = new THREE.Color(0, 0, 0);

    if (emitters.includes(hit.object) || (lightMesh && hit.object === lightMesh)) {
      let intensityhere;

      if (hit.object instanceof THREE.Light) {
        intensityhere = hit.object.intensity;
      } else {
        emitters.forEach((e) => {
          if (e instanceof THREE.RectAreaLight) {
            intensityhere = e.intensity;
          }
        });
      }

      // @ts-ignore
      if (current_bounce <= 1) {
        // @ts-ignore
        return hit.object.material.color.clone();
      }

      // @ts-ignore
      return hit.object.material.color.clone().multiplyScalar(intensityhere * 2);
    } else {
      const thisobject = hit.object;
      const matrixWorld = hit.object.matrixWorld;
      // @ts-ignore
      const posAttr = thisobject.geometry.attributes.position.clone().applyMatrix4(hit.object.matrixWorld);
      // @ts-ignore
      const normalAttr = thisobject.geometry.attributes.normal.clone();

      const hitpoint = hit.point;
      const hitface = hit.face;
      const triangle = new THREE.Triangle();
      const normal0 = new THREE.Vector3();
      const normal1 = new THREE.Vector3();
      const normal2 = new THREE.Vector3();
      let barycoord = new THREE.Vector3();
      // @ts-ignore
      triangle.a.fromBufferAttribute(posAttr, hitface.a);
      // @ts-ignore
      triangle.b.fromBufferAttribute(posAttr, hitface.b);
      // @ts-ignore
      triangle.c.fromBufferAttribute(posAttr, hitface.c);

      // @ts-ignore
      normal0.fromBufferAttribute(normalAttr, hitface.a);
      // @ts-ignore
      normal1.fromBufferAttribute(normalAttr, hitface.b);
      // @ts-ignore
      normal2.fromBufferAttribute(normalAttr, hitface.c);

      triangle.getBarycoord(hitpoint, barycoord);

      let normal = new THREE.Vector3();
      normal
        .setScalar(0)
        .addScaledVector(normal0, barycoord.x)
        .addScaledVector(normal1, barycoord.y)
        .addScaledVector(normal2, barycoord.z)
        .normalize();

      normal.transformDirection(hit.object.matrixWorld).normalize();
      let lastRay = raycaster.ray.direction.clone().negate().normalize();
      let cosThetaC = Math.min(1, lastRay.dot(normal.clone()));

      if (hit.object instanceof THREE.Mesh && hit.object.material) {
        if (
          hit.object.material instanceof THREE.MeshPhongMaterial ||
          hit.object.material instanceof THREE.MeshBasicMaterial
        ) {
          emitters.forEach((emitter) => {
            let samplePoint = sample_emitter(emitter);

            let shadowRayDirection = samplePoint.clone().sub(hit.point).normalize();
            let shadowRayDistance = samplePoint.distanceTo(hit.point);
            // @ts-ignore
            let shadowRaycaster = new THREE.Raycaster(hit.point, shadowRayDirection, epsilon, shadowRayDistance);
            let hit_emitter = shadowRaycaster.intersectObjects(objects, true)[0];
            if (!hit_emitter || (lightMesh && hit_emitter.object === lightMesh)) {
              if (hit.object instanceof THREE.Mesh) {
                let v = shadowRaycaster.ray.direction.clone();
                let cosTheta = Math.min(1, v.dot(normal.clone()));
                let surfaceColor = hit.object.material.color.clone();
                color.add(
                  emitter.color
                    .clone()
                    .multiply(surfaceColor)
                    .multiplyScalar(Math.max(0, cosTheta) * emitter.intensity),
                );
              }
            }
          });
          let reflectionDirection = getRandomReflectionDirection(normal);
          let reflectionRay = new THREE.Ray(hit.point, reflectionDirection.normalize());
          color.add(trace(reflectionRay).multiplyScalar(1 / Math.PI));
        } else if (hit.object.material instanceof THREE.MeshPhysicalMaterial) {
          let v = raycaster.ray.direction.clone().normalize();

          let eta = 1 / hit.object.material.ior;
          // @ts-ignore
          if (cosThetaC < 0) {
            eta = 1 / eta;
            cosThetaC = -cosThetaC;
            normal.multiplyScalar(-1);
          }

          let sinThetaSqr = 1 - cosThetaC * cosThetaC;

          let cosPhi = Math.sqrt(1 - eta * eta * sinThetaSqr);

          let n = new THREE.Vector3(0, 0, 1);
          if (n.dot(lastRay.clone()) < 0) {
            n = n.negate();
          }
          n.transformDirection(hit.object.matrixWorld);

          let refractionDirection = v
            .clone()
            .multiplyScalar(eta)
            .add(normal.multiplyScalar(eta * cosThetaC - cosPhi));
          let refractionRay = new THREE.Ray(hit.point, refractionDirection.normalize());
          color.add(trace(refractionRay));
        } else if (hit.object.material instanceof THREE.MeshStandardMaterial) {
          // @ts-ignore
          let reflectionDirection = raycaster.ray.direction.clone().reflect(normal.clone()).normalize();
          let reflectionRay = new THREE.Ray(hit.point, reflectionDirection);
          color.add(trace(reflectionRay));
        }
      }
    }
    return color;
  }
  return trace(seedray);
};
