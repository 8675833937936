export const boxReset = {
  name: 'box',
  scale: [1, 1, 1],
  color: 'gray',
  width: 2,
  maxWidth: 8,
  minWidth: 1,
  height: 2,
  maxHeight: 8,
  minHeight: 1,
  depth: 2,
  maxDepth: 8,
  minDepth: 1,
  widthSegments: 1,
  maxWidthSegments: 8,
  minWidthSegments: 1,
  heightSegments: 1,
  maxHeightSegments: 8,
  minHeightSegments: 1,
  depthSegments: 1,
  maxDepthSegments: 8,
  minDepthSegments: 1,
  isVisible: true,
  isFlat: false,
  isWireframe: false,
  isFaceNormals: false,
  isVertexNormals: false,
  metalness: 0.2,
  roughness: 0.8,
  transparency: 0,
  refractionRatio: 0,
};

export const sphereReset = {
  name: 'sphere',
  scale: [1, 1, 1],
  color: 'red',
  radius: 1.5,
  maxRadius: 2,
  minRadius: 1,
  widthSegments: 1024,
  maxWidthSegments: 1024,
  minWidthSegments: 3,
  heightSegments: 1024,
  maxHeightSegments: 1024,
  minHeightSegments: 3,
  isVisible: true,
  isFlat: false,
  isWireframe: false,
  isFaceNormals: false,
  isVertexNormals: false,
  // PBR
  metalness: 0.95,
  roughness: 0.05,
  transparency: 0,
  refractionRatio: 1,
  havemap: false,
  stainless: true,
  envMapIntensity: 1,
};

export const torusReset = {
  name: 'torus',
  position: [0, 0, 0],
  scale: [1, 1, 1],
  color: 'gray',

  radius: 2,
  maxRadius: 3,
  minRadius: 1,

  tubeWidth: 0.6,
  maxTubeWidth: 1,
  minTubeWidth: 0.1,

  radialSegments: 30,
  maxRadialSegments: 40,
  minRadialSegments: 2,

  tubularSegments: 90,
  maxTubularSegments: 200,
  minTubularSegments: 3,

  isVisible: true,
  isFlat: false,
  isWireframe: false,
  isFaceNormals: false,
  isVertexNormals: false,

  metalness: 0,
  roughness: 0.1,
  opacity: 1,
  refractionRatio: 0,
  reflectivity: 1,
  isTransparent: true,
  transmission: 1,
};
