import React, { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';

import { OrbitControls } from '../../../components/3d/OrbitControls';
import { RT } from '../../../stores';
import Lights from './Lights';
import { Box, Ground, Sphere, Torus, CornellBox } from './Objects';
import { FSQuad } from './fsquad';
import { SceneInfo } from './SceneInfo';

const Scene: React.FC = () => {
  const backgroundColor = RT.useStore((store) => store.state.scene.backgroundColor);
  const groups = RT.useStore((store: RT.Store) => store.state.objects.groups);
  const areaLightState = RT.useStore((store: RT.Store) => store.state.lights.area.isActive);
  const lightData = RT.useStore((store: RT.Store) => store.state.lights['area']);
  const spheres: RT.SceneObject[] = RT.useStore((store: RT.Store) => store.state.objects.sphere);
  const tori: RT.SceneObject[] = RT.useStore((store: RT.Store) => store.state.objects.torus);
  const boxes: RT.SceneObject[] = RT.useStore((store: RT.Store) => store.state.objects.box);

  const showPlane = RT.useStore((store: RT.Store) => store.state.scene.showPlane);
  const showCornellBox = RT.useStore((store: RT.Store) => store.state.scene.showCornellBox);
  const [faceCount, setFaceCount] = useState(0);
  const [sampleNum, setSampleNum] = useState(0);
  const [renderTime, setRenderTime] = useState(0);

  const handleFaceCountChange = (count: number) => {
    setFaceCount(count);
  };

  const handleSampleNumChange = (count: number) => {
    setSampleNum(count);
  };

  const handleRenderTimeChange = (time: number) => {
    setRenderTime(time);
  };

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Canvas
        onCreated={({ gl }): void => {
          gl.shadowMap.enabled = true;
          gl.shadowMap.type = THREE.PCFSoftShadowMap;
        }}
        camera={{
          position: new THREE.Vector3(0, 0, 13),
        }}
        style={{ background: backgroundColor }}
      >
        <Lights />
        {areaLightState && (
          <mesh position={lightData.position as [number, number, number]} rotation={[-Math.PI / 2, 0, 0]}>
            <planeBufferGeometry attach="geometry" args={[lightData.width, lightData.height]} />
            <meshStandardMaterial
              attach="material"
              emissive={lightData.color}
              side={THREE.DoubleSide}
              color={lightData.color}
            />
          </mesh>
        )}

        {areaLightState && showCornellBox && (
          <ambientLight
            position={lightData.position as [number, number, number]}
            color={lightData.color}
            intensity={lightData.intensity * 0.2}
          ></ambientLight>
        )}

        {areaLightState && !showCornellBox && (
          <directionalLight
            position={lightData.position as [number, number, number]}
            color={lightData.color}
            intensity={lightData.intensity * 0.2}
          ></directionalLight>
        )}

        {!showCornellBox && (
          <>
            {groups.sphere &&
              spheres.map((object: RT.SceneObject, index: number) => {
                return <Sphere key={index} index={index} sphere={object} />;
              })}
            {groups.torus &&
              tori.map((object: RT.SceneObject, index: number) => {
                return <Torus key={index} index={index} torus={object} />;
              })}
            {groups.box &&
              boxes.map((object: RT.SceneObject, index: number) => {
                return <Box key={index} index={index} box={object} />;
              })}
            {showPlane && <Ground />}
          </>
        )}
        {showCornellBox && <CornellBox />}

        <FSQuad
          onFaceCountChange={handleFaceCountChange}
          onSampleNumChange={handleSampleNumChange}
          onRenderTimeChange={handleRenderTimeChange}
        />

        <OrbitControls enablePan={true} />
      </Canvas>
      <SceneInfo faceCount={faceCount} sampleNum={sampleNum} renderTime={renderTime} />
    </div>
  );
};

export default Scene;
