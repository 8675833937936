import produce from 'immer';
import create from 'zustand';

import { Config, CNB, Plane } from './premades/premades';
import {
  ESceneLight,
  ESceneObject,
  EBSDFModel,
  SceneObject,
  BSDFParameters,
  Store,
  DielectricParameter,
  EIntegrator,
} from './types';

function changeBSDFParam(draft: Store, objectIdentifier: string, IOR?: number, color?: string): void {
  if (objectIdentifier === 'sphere' || objectIdentifier === 'torus' || objectIdentifier === 'box') {
    draft.state.objects[objectIdentifier].forEach((obj: SceneObject) => {
      if (color) {
        obj.color = color;
      } else if (IOR) {
        const m_IOR = IOR ? IOR : 1.5;
        obj.BSDFParameters.dielectric.m_IOR = m_IOR;
      }
    });
  }
}

function changeBSDFModel(
  draft: Store,
  objectIdentifier: string,
  model: EBSDFModel,
  IOR?: number,
  color?: string,
): void {
  if (objectIdentifier === 'sphere' || objectIdentifier === 'torus' || objectIdentifier === 'box') {
    draft.state.objects[objectIdentifier].forEach((obj: SceneObject) => {
      obj.BSDFModel = model;
      if (model == EBSDFModel.DIELECTRIC) {
        const m_IOR = IOR ? IOR : 1.5;
        obj.BSDFParameters.dielectric.m_IOR = m_IOR;
      }
      if (model == EBSDFModel.DIFFUSE) {
        if (color) {
          obj.color = color;
        }
      }
    });
  }
}

export const useStore = create<Store>((set) => {
  const update = (fn: any): any => set(produce(fn));

  return {
    state: CNB.config,
    default: CNB,

    actions: {
      registerObject: (objectID: string, type: ESceneObject, idx: number): void =>
        update((draft: Store) => {
          draft.state.objects[type][idx].id = objectID;
        }),

      toggleModelGroup: (type: ESceneObject): void =>
        update((draft: Store) => {
          draft.state.objects.groups[type] = !draft.state.objects.groups[type];
        }),

      selectObject: (objectID: string, type: ESceneObject): void =>
        update((draft: Store) => {
          draft.state.objects.selectedObject = {
            id: objectID,
            type: type,
          };
        }),

      setBSDFModel: (objectID: string, type: ESceneObject, BSDFModel: EBSDFModel): void =>
        update((draft: Store) => {
          draft.state.objects[type].forEach((obj: SceneObject) => {
            if (obj.id === objectID) {
              obj.BSDFModel = BSDFModel;
            }
          });
        }),

      setColor: (objectID: string, color: string, type: ESceneObject): void =>
        update((draft: Store) => {
          draft.state.objects[type].forEach((obj: SceneObject) => {
            if (obj.id === objectID) {
              obj.color = color;
            }
          });
        }),

      setBSDFParams: (objectID: string, type: ESceneObject, params: BSDFParameters, bsdfModel: EBSDFModel): void =>
        update((draft: Store) => {
          draft.state.objects[type].forEach((obj: SceneObject) => {
            if (obj.id === objectID) {
              if (bsdfModel === EBSDFModel.DIELECTRIC) {
                if ('m_IOR' in params) {
                  obj.BSDFParameters.dielectric = params as DielectricParameter;
                }
              }
            }
          });
        }),

      setGlobalBSDFModel: (model: EBSDFModel, color?: string): void =>
        update((draft: Store) => {
          draft.state.globals.BSDFModel = model;

          changeBSDFModel(draft, 'sphere', model);
          changeBSDFModel(draft, 'box', model);
          changeBSDFModel(draft, 'torus', model);
        }),

      toggleLight: (light: ESceneLight): void =>
        update((draft: Store) => {
          draft.state.lights[light].isActive = !draft.state.lights[light].isActive;

          if (draft.state.lights.activeLightSettings === light) {
            draft.state.lights.activeLightSettings = '';
          }

          if (draft.state.lights[light].isActive) {
            draft.state.lights.activeLightSettings = light;
          }
        }),

      toggleLightOptions: (light: ESceneLight): void =>
        update((draft: Store) => {
          if (draft.state.lights.activeLightSettings === light) {
            draft.state.lights.activeLightSettings = '';
          } else {
            draft.state.lights.activeLightSettings = light;
          }
        }),

      setLightColor: (color: string, light: ESceneLight): void =>
        update((draft: Store) => {
          draft.state.lights[light].color = color;
        }),

      setLightIntensity: (intensity: number, light: ESceneLight): void =>
        update((draft: Store) => {
          draft.state.lights[light].intensity = intensity;
        }),

      setLightPosition: (position: number[], light: ESceneLight): void =>
        update((draft: Store) => {
          draft.state.lights[light].position = position;
        }),

      setLightWidth: (width: number, light: ESceneLight): void =>
        update((draft: Store) => {
          if (light === ESceneLight.AREA) {
            draft.state.lights[light].width = width;
          }
        }),

      setLightHeight: (height: number, light: ESceneLight): void =>
        update((draft: Store) => {
          if (light === ESceneLight.AREA) {
            draft.state.lights[light].height = height;
          }
        }),

      setBackgroundColor: (color: string): void =>
        update((draft: Store) => {
          draft.state.scene.backgroundColor = color;
        }),

      setIntegrator: (integrator: EIntegrator): void =>
        update((draft: Store) => {
          draft.state.scene.integrator = integrator;
        }),

      setPathParameters: (parameters: number): void =>
        update((draft: Store) => {
          draft.state.scene.bounce = parameters;
        }),

      togglePlaneVisibility: (): void =>
        update((draft: Store) => {
          draft.state.scene.showPlane = !draft.state.scene.showPlane;
        }),
      toggleCornellBoxVisibility: (): void =>
        update((draft: Store) => {
          draft.state.scene.showCornellBox = !draft.state.scene.showCornellBox;
        }),
      setState: (config: Config): void =>
        update((draft: Store) => {
          draft.default = config;
          draft.state = config.config;
        }),
      resetState: (): void =>
        update((draft: Store) => {
          draft.state = draft.default.config;
        }),
      resetLights: (): void =>
        update((draft: Store) => {
          draft.state.lights = draft.default.config.lights;
        }),
      resetObjects: (): void =>
        update((draft: Store) => {
          draft.state.globals = draft.default.config.globals;
          draft.state.objects = draft.default.config.objects;
        }),
    },
  };
});

export const api = useStore.getState();
