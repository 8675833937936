import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RT } from '../../../../../stores';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 3),
    },
    toggleButton: {
      width: 128,
    },
    selected: {
      '&$selected': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  }),
);

interface Props {
  objectData: RT.SceneObject;
  setBSDFModel: (id: string, type: RT.ESceneObject, BSDFModel: RT.EBSDFModel) => void;
}

export const BSDFModelToggle: React.FC<Props> = ({ objectData, setBSDFModel }) => {
  const classes = useStyles();

  return (
    <div style={{ marginBottom: '1em' }}>
      <ToggleButtonGroup exclusive size="small">
        <ToggleButton
          className={classes.toggleButton}
          classes={{
            selected: classes.selected,
          }}
          onClick={(): void => setBSDFModel(objectData.id, objectData.type, RT.EBSDFModel.DIFFUSE)}
          selected={objectData.BSDFModel === RT.EBSDFModel.DIFFUSE}
          key={uuidv4()}
          value={'toggle'}
        >
          <Typography variant="subtitle2">Diffuse</Typography>
        </ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          classes={{
            selected: classes.selected,
          }}
          onClick={(): void => setBSDFModel(objectData.id, objectData.type, RT.EBSDFModel.MIRROR)}
          selected={objectData.BSDFModel === RT.EBSDFModel.MIRROR}
          key={uuidv4()}
          value={'toggle'}
        >
          <Typography variant="subtitle2">Mirror</Typography>
        </ToggleButton>
        {/* <ToggleButton
          className={classes.toggleButton}
          classes={{
            selected: classes.selected,
          }}
          onClick={(): void => setBSDFModel(objectData.id, objectData.type, RT.EBSDFModel.DIELECTRIC)}
          selected={objectData.BSDFModel === RT.EBSDFModel.DIELECTRIC}
          key={uuidv4()}
          value={'toggle'}
        >
          <Typography variant="subtitle2">GLASS</Typography>
        </ToggleButton> */}
      </ToggleButtonGroup>
    </div>
  );
};
