/* eslint-disable @typescript-eslint/explicit-function-return-type */
// ModelContext.js
import React, { createContext, useContext, useState } from 'react';

// 创建上下文
const EnvContext = createContext();

export const EnvProvider = ({ children }) => {
  const [env, setEnv] = useState(null);

  return <EnvContext.Provider value={{ env, setEnv }}>{children}</EnvContext.Provider>;
};

export const useEnvContext = () => {
  return useContext(EnvContext);
};

export const useEnv = () => {
  return useEnvContext();
};
