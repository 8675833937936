import React from 'react';
import * as THREE from 'three';

import { RT } from '../../../../stores';

interface Props {
  BSDFModel: RT.EBSDFModel;
  object: RT.SceneObject;
}

export const MaterialFromBSDFModel: React.FC<Props> = ({ BSDFModel, object }) => {
  if (BSDFModel === RT.EBSDFModel.DIFFUSE) {
    return <meshPhongMaterial color={new THREE.Color(object.color)} />;
  } else if (BSDFModel === RT.EBSDFModel.MIRROR) {
    return <meshStandardMaterial roughness={0.0} metalness={1.0} />;
  } else {
    return <meshPhysicalMaterial ior={1.5} transmission={1.0} opacity={1.0} transparent={true} />;
  }
};
