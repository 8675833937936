import * as THREE from 'three';

export const sample_emitter = (emitter) => {
  if (emitter instanceof THREE.PointLight) {
    return emitter.position;
  } else {
    const randomX = emitter.position.x + (Math.random() - 0.5) * emitter.width;
    const randomY = emitter.position.y + (Math.random() - 0.5) * emitter.height;
    const randomZ = emitter.position.z; // Assuming z-coordinate remains unchanged
    return new THREE.Vector3(randomX, randomY, randomZ);
  }
};
