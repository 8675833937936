export const complexReset = {
  name: 'complex',
  scale: [1, 1, 1],
  // AO
  intensity: 0.5,
  radius: 0.3,
  isDiffuse: true,
  isAO: true,
  isOBJ: false,
  isBox: true,
};
