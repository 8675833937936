import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import React from 'react';

import { RT } from '../../../../stores';

export const PlaneVisibilityToggle = (): JSX.Element => {
  const showPlane = RT.useStore((store: RT.Store) => store.state.scene.showPlane);
  const showCornellBox = RT.useStore((store: RT.Store) => store.state.scene.showCornellBox);
  const togglePlane = RT.useStore((store: RT.Store) => store.actions.togglePlaneVisibility);
  const toggleCornellBox = RT.useStore((store: RT.Store) => store.actions.toggleCornellBoxVisibility);

  if (showCornellBox) {
    return <></>;
  }
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch color="primary" checked={showPlane} onChange={(): void => togglePlane()} value={2} />}
        label="Plane"
      />
    </FormGroup>
  );
};
