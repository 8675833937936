import React, { useEffect, useRef, useState } from 'react';

import { RT } from '../../../../../stores';
import { MaterialFromBSDFModel } from '../MaterialFromBSDFModel';

interface Props {
  index: number;
  box: RT.SceneObject;
}

export const Box: React.FC<Props> = ({ index, box }) => {
  const ref = useRef<any>();
  const [objectID, setObjectID] = useState('');
  const actions = RT.useStore((store: RT.Store) => store.actions);
  const objects = RT.useStore((store: RT.Store) => store.state.objects);
  const geometryRef = useRef();
  const geometry = geometryRef.current;

  const registerObject = (): void => {
    actions.registerObject(ref.current.uuid, RT.ESceneObject.BOX, index);
    setObjectID(ref.current.uuid);
  };

  const selectObject = (): void => {
    actions.selectObject(ref.current.uuid, RT.ESceneObject.BOX);
  };

  useEffect(() => {
    registerObject();
  });

  return (
    <mesh ref={ref} castShadow position={box.position as [number, number, number]} onPointerDown={selectObject}>
      <boxBufferGeometry ref={geometryRef} attach="geometry" args={[1, 1, 1, 10, 10, 10]} />
      <MaterialFromBSDFModel BSDFModel={box.BSDFModel} object={box} />
    </mesh>
  );
};
