import { FormControlLabel, Slider, Switch } from '@material-ui/core';
import React from 'react';

import { ColorSelector } from '../../../../components/colorSelector/ColorSelector';
import { LabelWithValue } from '../../../../components/forms/LabelWithValue';
import { StyledDivider } from '../../../../components/layouts/StyledDivider';
import { RT } from '../../../../stores';

export const PointLightOptions = (): JSX.Element => {
  const actions = RT.useStore((store: RT.Store) => store.actions);
  const light = RT.useStore((store: RT.Store) => store.state.lights.point);

  function valuetext(value: number): string {
    return `${value}`;
  }

  return (
    <>
      <ColorSelector
        caption="light color"
        activeColor={light.color}
        onColorChange={(color): void => actions.setLightColor(color, RT.ESceneLight.POINT)}
      />

      <StyledDivider />
      <LabelWithValue label={'Intensity'} value={light.intensity} />
      <Slider
        value={light.intensity}
        getAriaValueText={valuetext}
        step={0.1}
        min={0}
        max={1}
        marks
        onChange={(_, v): void => actions.setLightIntensity(+v, RT.ESceneLight.POINT)}
      />
    </>
  );
};
