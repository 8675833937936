import { a, useSpring } from '@react-spring/three';
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

import { RT } from '../../../../../stores';
import { MaterialFromBSDFModel } from '../MaterialFromBSDFModel';

interface Props {
  index: number;
  torus: RT.SceneObject;
}

export const Torus: React.FC<Props> = ({ index, torus }) => {
  const ref = useRef<any>(null);
  const [objectID, setObjectID] = useState('');
  const actions = RT.useStore((store: RT.Store) => store.actions);
  const objects = RT.useStore((store: RT.Store) => store.state.objects);
  const geometryRef = useRef();
  const geometry = geometryRef.current;

  const props = useSpring({ scale: objects.selectedObject.id === objectID ? [1.02, 1.02, 1.02] : [1, 1, 1] });

  const registerObject = (): void => {
    if (ref.current) {
      actions.registerObject(ref.current.uuid, RT.ESceneObject.TORUS, index);
      setObjectID(ref.current.uuid);
    }
  };

  const selectObject = (): void => {
    if (ref.current) {
      actions.selectObject(ref.current.uuid, RT.ESceneObject.TORUS);
    }
  };

  useEffect(() => {
    registerObject();
  });

  return (
    <mesh
      ref={ref}
      castShadow
      position={torus.position as [number, number, number]}
      onPointerDown={selectObject}
      rotation={[-Math.PI / 2, 0, 0]}
    >
      <torusBufferGeometry ref={geometryRef} attach="geometry" args={[0.75, 0.3, 8, 16]} />
      <MaterialFromBSDFModel BSDFModel={torus.BSDFModel} object={torus} />
    </mesh>
  );
};
