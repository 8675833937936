import { Config } from './premades/premades';

export interface Store {
  default: Config;
  state: State;
  actions: Actions;
}

export interface State {
  globals: Globals;

  lights: {
    activeLightSettings: ESceneLight | string;
    ['area']: AreaLight;
    ['point']: PointLight;
  };

  objects: {
    selectedObject: {
      id: string;
      type: ESceneObject;
    };
    groups: {
      ['torus']: boolean;
      ['sphere']: boolean;
      ['box']: boolean;
    };
    sphere: SceneObject[] | [];
    box: SceneObject[] | [];
    torus: SceneObject[] | [];
  };

  scene: {
    integrator: EIntegrator;
    bounce: number;
    backgroundColor: string;
    showPlane: boolean;
    showCornellBox: boolean;
  };
}

export interface Actions {
  registerObject: (objectID: string, type: ESceneObject, idx: number) => void;
  toggleModelGroup: (type: ESceneObject) => void;
  selectObject: (id: string, type: ESceneObject) => void;
  setColor: (id: string, color: string, type: ESceneObject) => void;
  toggleLight: (light: ESceneLight) => void;
  toggleLightOptions: (light: ESceneLight) => void;
  setLightColor: (color: string, light: ESceneLight) => void;
  setLightIntensity: (intensity: number, light: ESceneLight) => void;
  setLightPosition: (position: number[], light: ESceneLight) => void;
  setLightWidth: (width: number, light: ESceneLight) => void;
  setLightHeight: (height: number, light: ESceneLight) => void;
  setBSDFModel: (objectID: string, type: ESceneObject, BSDFModel: EBSDFModel) => void;
  setBSDFParams: (objectID: string, type: ESceneObject, params: BSDFParameters, BSDFModel: EBSDFModel) => void;
  setGlobalBSDFModel: (model: EBSDFModel, color?: string) => void;
  setBackgroundColor: (color: string) => void;
  setIntegrator: (integrator: EIntegrator) => void;
  setPathParameters: (parameters: number) => void;
  togglePlaneVisibility: () => void;
  toggleCornellBoxVisibility: () => void;
  setState: (config: Config) => void;
  resetState: () => void;
  resetLights: () => void;
  resetObjects: () => void;
}

export interface SceneObject {
  type: ESceneObject;
  id: string;
  selected: boolean;
  visible: boolean;
  position: number[];
  color: string;
  BSDFModel: EBSDFModel;
  BSDFParameters: {
    diffuse: DiffuseParameter;
    dielectric: DielectricParameter;
    mirror: BSDFParameters;
  };
}

export interface SceneLight {
  isActive: boolean;
  name: string;
  type: ESceneLight;
  position: number[];
  intensity: number;
  color: string;
}

interface Globals {
  BSDFModel: 'diffuse' | 'dielectric' | 'mirror';
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BSDFParameters {}

export interface DielectricParameter extends BSDFParameters {
  m_IOR: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DiffuseParameter extends BSDFParameters {}

export interface AreaLight extends SceneLight {
  intensity: number;
  width: number;
  height: number;
}

export interface PointLight extends SceneLight {
  intensity: number;
}

export enum ESceneLight {
  POINT = 'point',
  AREA = 'area',
}

export enum ESceneObject {
  BOX = 'box',
  TORUS = 'torus',
  SPHERE = 'sphere',
}

// set up some BSDF types of object
export enum EBSDFModel {
  DIFFUSE = 'diffuse',
  DIELECTRIC = 'dielectric',
  MIRROR = 'mirror',
}

export enum EIntegrator {
  WHITTED = 'whitted',
}
